import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
// Services
import { getCustomer } from './services/customer';
import { postIssue } from './services/issue';
// Sentry init
import { isInSentryExceptionsAllowList } from './services/sentryAllowList';
// Components
import Error from './components/templates/error/Error';
import TextBloc from './components/organisms/TextBloc/TextBloc';
import RevealFadeIn from './components/animations/RevealFadeIn';
import Form from './components/templates/form/Form';
import MorphingBlob from './components/atoms/MorphingBlob/MorphingBlob';
import Footer from './components/organisms/Footer/Footer';
import Header from './components/organisms/Header/Header';
import PhoneBloc from './components/molecules/PhoneBloc/PhoneBloc';
// Pictures
import Picture from './assets/ZOL-Doudou-footer.webp';
// Styles
import styles from './App.module.css';

const storedValues = localStorage.getItem('issue');

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  /**
   * @param {Sentry.ErrorEvent} event
   * @param {object} hint
   * @param {import('axios').AxiosError | Error} hint.originalException
   * @returns {Sentry.ErrorEvent | null}
   */
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (error instanceof AxiosError) {
      if (isInSentryExceptionsAllowList(error)) {
        return null;
      }
      return {
        ...event,
        extra: {
          ...event.extra,
          error: error.response.data,
        },
      };
    }
    return event;
  },

  enabled: !import.meta.env.DEV,
  environment: import.meta.env.MODE,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

/**
 * Converts a file to base64 format.
 * @param {File} file
 * @returns {Promise<string>}
 */
const fileToBase64 = async (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  // Split the base64 string to get only the data and not the metadata
  reader.onload = () => (typeof reader.result === 'string' ? resolve(reader.result.split(',')[1]) : reject());
  reader.onerror = reject;
  reader.readAsDataURL(file);
});

/**
 * Convert a list of objects to a list of options
 * @param {object[]} list
 * @param {object} options
 * @param {string} options.attributeLabel
 * @param {string} options.attributeValue
 * @returns {{label: string, value: string | number}[]}
 */
const listToOptions = (list, { attributeLabel, attributeValue }) => (
  list.map(
    /**
     * @param {{[index: string]: any}} item
     * @returns {{label: string, value: string | number}}
     */
    (item) => ({ label: item[attributeLabel], value: item[attributeValue] }),
  )
);

/**
 * App component
 * @returns {JSX.Element}
 */
function App() {
  // States
  /** @type {[Customer|null, React.Dispatch<Customer|null>]} */
  const [customer, setCustomer] = useState(null);
  /** @type {[Error|null, React.Dispatch<Error|null>]} */
  const [error, setError] = useState(null);
  /** @type {[boolean, React.Dispatch<boolean>]} */
  const [loading, setLoading] = useState(true);
  /** @type {[boolean, React.Dispatch<boolean>]} */
  const [postingIssue, setPostingIssue] = useState(false);
  /** @type {[boolean, React.Dispatch<boolean>]} */
  const [postingIssueSuccess, setPostingIssueSuccess] = useState(false);
  /** @type {string} */
  const clientHashFromPath = window.location.hash.replace('#', '');
  /** @type {Omit<Issue, 'customerId'>|null} */
  const defaultValues = storedValues ? JSON.parse(storedValues) : null;

  // Hooks
  const { t } = useTranslation();

  useEffect(() => {
    getCustomer(clientHashFromPath)
      .then((response) => {
        setCustomer(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  // Handlers

  /**
   * Handles the form submission.
   * @param {object} values - The form values.
   * @param {string} values.missionId
   * @param {string} values.scope
   * @param {string} values.severity
   * @param {string} values.subject
   * @param {string} values.description
   * @param {string} values.steps
   * @param {string} values.target
   * @param {File} values.attachments
   * @param {boolean} values.isUrgent
   * @param {string} values.email
   * @param {string} values.phone
   * @param {boolean} values.hasAcceptedCommunication
   */
  const handleFormSubmit = async (values) => {
    const imageBase64 = values.attachments
      ? await fileToBase64(values.attachments)
      : null;
    const newIssue = {
      ...values,
      customerId: clientHashFromPath,
      missionId: Number(values.missionId),
      attachments: imageBase64 && typeof imageBase64 === 'string'
        ? [
          { name: values.attachments.name, data: imageBase64 },
        ]
        : [],
    };
    setPostingIssue(true);
    // Save form data in local storage in case something goes wrong
    localStorage.setItem('issue', JSON.stringify(newIssue));
    // Send data through API Call
    postIssue(newIssue)
      .then(() => {
        setPostingIssue(false);
        // If call succeed clear values cached
        localStorage.removeItem('issue');
        setPostingIssueSuccess(true);
        // Scroll to top to display success message
        window.scrollTo(0, 0);
      })
      .catch((postError) => {
        setPostingIssue(false);
        setError(postError);
      });
  };

  if (error) return <Error />;

  if (loading) return null;

  return (
    <>
      <Header />
      <main className={styles.main}>
        <TextBloc
          className={styles['text-bloc']}
          title={`${t('app.title')}<br /><span>${customer.name} !</span>`}
          text={t('app.text')}
          action={(
            <>
              {customer.productOwnerPhone ? (
                <PhoneBloc
                  className={styles['added-button']}
                  text={t('app.productOwnerPhoneText', { name: customer.productOwnerName })}
                  phone={customer.productOwnerPhone}
                />
              ) : null}
              <PhoneBloc text={t('app.agencyPhoneText')} phone={t('app.phone')} />
            </>
          )}
        />

        <RevealFadeIn className="card-template">
          <Form
            defaultValues={defaultValues}
            onSubmit={handleFormSubmit}
            missionOptions={listToOptions(customer.missions, { attributeLabel: 'name', attributeValue: 'id' })}
            disabled={postingIssue}
            hasSubmissionSucceeded={postingIssueSuccess}
            submitButtonLabel={postingIssue ? t('form.submitButtonPendingLabel') : t('form.submitButtonLabel')}
          />
        </RevealFadeIn>

      </main>
      <Footer
        link={{
          legal: {
            href: 'https://zol.fr/politique-de-confidentialite',
            label: t('app.footer.legalLabel'),
          },
        }}
      />
      <MorphingBlob
        width="60%"
        height="130vh"
        background="violet"
        className={styles['top-blob']}
      />
      <img src={Picture} alt="ZOL Doudou" className={styles.picture} />
    </>
  );
}

export default App;
